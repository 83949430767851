import React from "react";
// Basic
// import CookieConsent from "react-cookie-consent";

// Option
import CookieConsent from "react-cookie-consent";

const Popup = () => {
  return (
    <div>     
      <CookieConsent
        location="bottom"
        enableDeclineButton
        buttonText="OK , verstanden"
        buttonClasses="btn btn-primary"
        declineButtonText="Nein das möchte ich nicht"
        cookieName="Live Stopps Cookie"
        onDecline = {() =>{

        }}
        //containerClasses="alert alert-warning"
        
       // visible={true}
        //style={{ background: "#2B373B" }}
        //buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={365}
      >
        Diese Website verwendet Cookies, um die Benutzererfahrung zu verbessern.{" "}       
      </CookieConsent>
    </div>
  );
};

export default Popup;