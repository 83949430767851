import React, { useState, useEffect } from "react";

import "../../../App.css";
import { Card, Container, Button, Row } from "react-bootstrap";
import { useAuth } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";
import { ref, get, child, getDatabase, onValue } from "firebase/database";
import BootstrapTable from "react-bootstrap-table-next";
import ImgRed from "../../images/button_red.png";
import ImgGreen from "../../images/button_green.png";
import {
  addDays,
  subDays, 
  format,
} from "date-fns";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruckRampBox,
  faSquareParking,
  faCheck,
  faLeftLong,
  faRightLong,
} from "@fortawesome/free-solid-svg-icons";

import "../live/live.css";



export const Live = () => {
  const truckRanpBox = <FontAwesomeIcon icon={faTruckRampBox} />;
  const SquareParking = <FontAwesomeIcon icon={faSquareParking} />;
  const Check = <FontAwesomeIcon icon={faCheck} />;
  const LeftLong = <FontAwesomeIcon icon={faLeftLong} />;
  const RightLong = <FontAwesomeIcon icon={faRightLong} />;

  const { currentUser, isAdmin } = useAuth();
  const [FID, setFID] = useState("");
  const [dbRef, SetdbRef] = useState([]);
  const [user, setUser] = useState([]);
  const [verguettung, setVerguettung] = useState([]);
  const [stoppsdata, setStoppsData] = useState([]);
  const [loadingVerguettung, setLoadingVerguettung] = useState(true);
  const [loadingUser, setLoadingUser] = useState(true);
  const [mainData , SetMainData] = useState([])
  const [gesamtbetrag, setGesamtBetrag] = useState(0);
  const [DataLoad, setDataLoad] = useState(false);
  
  const [dayshow, setDayShow] = useState(new Date());
  const [milltoDay, setMilltoDay] = useState(new Date().setUTCHours(0, 0, 0, 0));

  const [gesamtstopps, setGesamtStopps] = useState(0);
  const [durchschnittbetrag, setDurchschnittBetrag] = useState(0);
  const [durchschnittstopps, setDurchschnittstopps] = useState(0);

  const navigate = useNavigate();
  //const milltoDay = new Date().setUTCHours(0, 0, 0, 0);
  const db = getDatabase();

  const emptyDataMessage = () => { return 'Keine Daten vorhanden';}


  useEffect(() => {
    if (currentUser) {
      setFID(currentUser.photoURL);
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (!isAdmin ) {
      navigate("/Dashbord", { replace: true });
    }   
  }, [isAdmin, navigate]);

  const formatStopps = (cell, row) => {
    if (cell >= 20) {
      return (
        <span>
          <strong style={{ color: "green" }}>{cell}</strong>
        </span>
      );
    } else if (cell >= 16) {
      return (
        <span>
          <strong style={{ color: "blue" }}>{cell}</strong>
        </span>
      );
    }
    return <strong style={{ color: "red" }}>{cell}</strong>;
  };

  const formatArt = (cell, row) => {
    if (cell === true) {
      return (
        <span>
          <strong style={{ color: "green" }}>
            <img src={ImgGreen} width={15} height={15} alt="import"></img>
          </strong>
        </span>
      );
    }
    return (
      <strong style={{ color: "red" }}>
        {" "}
        <img src={ImgRed} width={15} height={15} alt="import"></img>{" "}
      </strong>
    );
  };

  const formatSLS = (cell, row) => {
    if (cell >= 16) {
      return (
        <span>
          <strong style={{ color: "green" }}>{cell}</strong>
        </span>
      );
    } else if (cell >= 14) {
      return (
        <span>
          <strong style={{ color: "blue" }}>{cell}</strong>
        </span>
      );
    }
    return <strong style={{ color: "red" }}>{cell}</strong>;
  };

  const formatTour = (cell, row) => {
    let context = <img src={ImgRed} width={15} height={15} alt="import"></img>;
    let beentladepunkt = <i style={{ color: "red" }}>{truckRanpBox}</i>;
    let parking = <i style={{ color: "red" }}>{SquareParking}</i>;
    let tagesabschluss = <i style={{ color: "red" }}>{Check}</i>;

    if (row.connect === true) {
      context = <img src={ImgGreen} width={15} height={15} alt="import"></img>;
    }
    if (row.ladestelle === true) {
      beentladepunkt = <i style={{ color: "green" }}>{truckRanpBox}</i>;
    }
    if (row.feierabendzone === true) {
      parking = <i style={{ color: "green" }}>{SquareParking}</i>;
    }
    if (row.tagesabschluss === true) {
      tagesabschluss = <i style={{ color: "green" }}>{Check}</i>;
    }

    return (
      <span>
        <strong style={{ color: "blue" }}>{cell}</strong>
        <div className="d-flex justify-content-around">
          {context}
          {beentladepunkt}
          {parking}
          {tagesabschluss}
        </div>
      </span>
    );
  };

  const formatVerguettung = (cell, row) => {
    if (cell >= 500) {
      return (
        <span>
          <strong style={{ color: "green" }}>{numberFormat(cell)}</strong>
        </span>
      );
    } else if (cell >= 460) {
      return (
        <span>
          <strong style={{ color: "blue" }}>{numberFormat(cell)}</strong>
        </span>
      );
    }
    return <strong style={{ color: "red" }}>{numberFormat(cell)}</strong>;
  };

  const formatDurchschnitt = (cell) => {
    if (cell >= 480) {
      return (
        <span>
          <strong style={{ color: "green" }}>{numberFormat(cell)}</strong>
        </span>
      );
    } else if (cell >= 440) {
      return (
        <span>
          <strong style={{ color: "blue" }}>{numberFormat(cell)}</strong>
        </span>
      );
    }
    return <strong style={{ color: "red" }}>{numberFormat(cell)}</strong>;
  };

  const columns = [
    {
      dataField: "id",
      text: "USerID",
      headerAlign: "center",
      hidden: true,
      style: { width: "90px" },
    },
    {
      dataField: "tour",
      text: "Tour",
      formatter: formatTour,
      headerAlign: "center",
      align: "center",
      sort: true,
      style: { width: "90px" },
    },
    {
      dataField: "name",
      text: "Mitarbeiter",
      headerAlign: "center",
      hidden: true,
      style: { width: "90px" },
    },
    {
      dataField: "sls",
      text: "SLS",
      formatter: formatSLS,
      headerAlign: "center",
      align: "center",
      sort: true,
      style: { width: "90px" },
    },
    {
      dataField: "stopps",
      text: "Stopps",
      formatter: formatStopps,
      headerAlign: "center",
      align: "center",
      sort: true,
      style: { width: "90px" },
    },
    {
      dataField: "connect",
      text: "Verbunden",
      headerAlign: "center",
      formatter: formatArt,
      align: "center",
      hidden: true,
      style: { width: "90px" },
    },
    {
      dataField: "verguettung",
      text: "Gage",
      formatter: formatVerguettung,
      headerAlign: "center",
      align: "center",
      sort: true,
      style: { width: "90px" },
    },
    {
      dataField: "verguettungOhneDieselundMaut",
      text: "Gage o. D. und M.",
      formatter: formatVerguettung,
      headerAlign: "center",
      align: "center",
      sort: true,
      style: { width: "90px" },
    },    
    {
      dataField: "maut",
      text: "Maut",
      formatter: formatVerguettung,
      headerAlign: "center",
      align: "center",
      sort: true,
      style: { width: "90px" },
    },
    {
      dataField: "Dieselzuschlag",
      text: "Diesel",
      formatter: formatVerguettung,
      headerAlign: "center",
      align: "center",
      sort: true,
      style: { width: "90px" },
    },
  ];




  useEffect(() => {
    //User Laden
    if (FID) {
      get(child(ref(getDatabase()), `users/` + FID))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            const unsort = Object.keys(data).map((k) => ({
              id: k,
              ...data[k],
            }));
            const resual = unsort.filter((user) => user.isDelete === false);
            setUser(resual);
            setLoadingUser(false);
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [dbRef, FID]);

  useEffect(() => {
    //Verguettungen Laden
    if (loadingUser) {
      get(child(ref(getDatabase()), `verguetung/` + FID))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            const unsort = Object.keys(data).map((k) => ({
              id: k,
              ...data[k],
            }));
            const resual = unsort.filter(
              (verguettung) => verguettung.isAktiv === true
            );

            setVerguettung(resual);
            setLoadingVerguettung(false);
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [loadingUser, FID]);

  useEffect(() => {
    if (!loadingVerguettung && !loadingUser) {  
     
      return onValue(
        ref(db, `dailybusiness/` + currentUser.photoURL + "/" + milltoDay),
        (data) => {            
            SetMainData(data)
            
        }
      );
    }
  }, [milltoDay, dayshow, loadingVerguettung, loadingUser, db, currentUser.photoURL ]);



  useEffect(() => {
    setStoppsData([]);
    setGesamtBetrag(0);
    setGesamtStopps(0);
    setDurchschnittBetrag(0)
    setDurchschnittstopps(0)

    if(mainData.exists && mainData.val() !== null){    
          setDataLoad(true)

          let count = 0;
         
          Object.entries(mainData.val().anwesenheit).forEach(([key, value]) => {
            count += 1;
            let UStopps = []
            let gesamtverguettung = 0;
            let verguettungOhneDieselundMaut = 0
            let maut = 0;
            let Dieselzuschlag = 0;
          
            if(mainData.val().stopps){
              UStopps = Object.values(mainData.val().stopps).filter(
                (x) => x.userid === key
              );

              const Verguettungsresult = verguettung.find((item) =>
                item.tourassign.find((tour) => tour.label === value.Tour)
              );
              
              if(UStopps.length >0){

                const grundsumme =
                Number(Verguettungsresult.pauschale) +               
                UStopps.length * Number(Verguettungsresult.prostopp);

                maut = Number(Verguettungsresult.maut);

                Dieselzuschlag = grundsumme * (1 + Number(Verguettungsresult.diselzuschlag) / 100) - grundsumme;
              
                verguettungOhneDieselundMaut = grundsumme

               // Dieselzuschlag = verguettungOhneDieselundMaut * (1 + Number(Verguettungsresult.diselzuschlag) / 100) - grundsumme;
              
                // Gesamtsumme inklusive Dieselzuschlag
                gesamtverguettung = grundsumme  * (1 + Number(Verguettungsresult.diselzuschlag) / 100) + maut;

           /*    gesamtverguettung =
                  Number(Verguettungsresult.pauschale) +
                  Number(Verguettungsresult.maut) +
                  UStopps.length * Number(Verguettungsresult.prostopp);*/
              }

            
              setGesamtBetrag((gesamtbetrag) => gesamtbetrag + gesamtverguettung);
              setGesamtStopps((gesamtstopps) => gesamtstopps + UStopps.length);

              setDurchschnittBetrag((durchschnittbetrag) => gesamtbetrag / count);
              setDurchschnittstopps((durchschnittstopps) => gesamtstopps / count);
            
            }
            const usernow = user.find((a) => a.id === key);
            let kennzeichen = "";
            let FZID = "";
            if (typeof value.Fahrzeug !== "undefined") {
              kennzeichen = value.Fahrzeug.Kennzeichen;
              FZID= value.Fahrzeug.ID;
            }

            try {
              setStoppsData((stoppsdata) => [
                ...stoppsdata,
                {
                  id: key,
                  name: usernow.name,
                  sls: value.SLS,
                  stopps: UStopps.length,
                  ladestelle: value.beentladepunkt,
                  feierabendzone: value.zonefeierabend,
                  present: true,
                  tour: value.Tour,
                  kennzeichen: kennzeichen,
                  FZID: FZID,
                  datetime: mainData.key,
                  connect: value.connections,
                  tagesabschluss: value.tagesabschluss,
                  verguettung: gesamtverguettung,
                  verguettungOhneDieselundMaut: verguettungOhneDieselundMaut,
                  maut: maut,
                  Dieselzuschlag: Dieselzuschlag,
                },
              ]);
            } catch (err) {
              console.log(err);
            }
          });
        }
  
  }, [gesamtbetrag, gesamtstopps, mainData, user, verguettung]);





  const numberFormat = (value) =>
    new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(value);


    const rowEvents = {
      onClick: (e, row, rowIndex) => {
      console.log("e=>",e)
      console.log("row=>",row)
      console.log("rowIndex=>",rowIndex)

      }
    };









  return (
    <>
      <Container fluid>
        <Card className="mt-3 bg-light align-items-stretch myCard">
         
           
              <div className="d-flex justify-content-around align-items-center">
                <Button 
                variant="outline-success"
                onClick={() => {                   
                      setMilltoDay(subDays(dayshow, 1).setUTCHours(0, 0, 0, 0))
                      setDayShow(subDays(dayshow, 1).setUTCHours(0, 0, 0, 0))
                    }}
                
                >{LeftLong}</Button>

                <div className="mt-3">
                  <strong>
                    <h1>Gesamt: {numberFormat(gesamtbetrag)} </h1>
                    <h3 className="text-center" >{gesamtstopps} Stopps</h3>
                  </strong>
                  <strong>
                    <h1>
                      Durchschnitt: {formatDurchschnitt(durchschnittbetrag)}{" "}
                    </h1>
                    <h3 className="text-center">{durchschnittstopps.toFixed(2)} Stopps</h3>
                    <h5 className="text-center">Daten für den {format(dayshow, 'dd.MM.yyyy')} </h5>
                  </strong>
                </div>

                <Button variant="outline-success"
                 onClick={() => {
                      setMilltoDay(addDays(dayshow, 1).setUTCHours(0, 0, 0, 0))
                      setDayShow(addDays(dayshow, 1).setUTCHours(0, 0, 0, 0))                    
                    }}
                
                
                >{RightLong}</Button>
              </div>
           
          
          <Card.Body>
            <div style={{ padding: "1px" }}>
              <BootstrapTable
                rowEvents={ rowEvents }
                bootstrap4={true}
                keyField="id"
                data={stoppsdata}
                noDataIndication={emptyDataMessage}
                columns={columns}
                loading={true}
              ></BootstrapTable>
            </div>         
            
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
