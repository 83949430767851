import React from "react";
import { Button, Modal, } from "react-bootstrap";




function InfoModal(props) {
    
    return (
            <Modal {...props}>
                <Modal.Header >
                    <Modal.Title>{props.titeltext}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{props.bodytext}</Modal.Body>
                <Modal.Footer>
                <Button variant="outline-danger" onClick={props.onHide}>
                        Abrechen
                    </Button>    
                    <Button variant="outline-success" onClick={props.handleok}>
                        OK
                    </Button>   
                                    
                </Modal.Footer>
            </Modal>       
    );
}

export default InfoModal;

