import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { ref, getDatabase, update, onValue, remove, push } from 'firebase/database';
import { useLocation,useNavigate } from 'react-router-dom';
import { useAuth } from "../../../context/authContext";

const MapMarker = ({ text }) => <div>{text}</div>;

const GoogleMapComponent = () => {
  const { currentUser, isAdmin } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();  
  const [zones, setZones] = useState([]);
  const [newZone, setNewZone] = useState({ lat: -3.745, lng: -38.523 });  
  const [radius, setRadius] = useState(1000);
  const [bezeichnung, setbezeichnung] = useState('');  
  const [FID, setFID] = useState('');  
  const zoneCollectionPath = `geo/${FID}`;
  const db = getDatabase();


  useEffect(() => {//Firma Laden        
    if (currentUser) {
        setFID(currentUser.photoURL);
    }
}, [currentUser]);

/*useEffect(() => {// Is Admin?
  if (!isAdmin ) {
      navigate("/Dashbord", { replace: true });
  }   
}, [isAdmin, navigate]);*/


  useEffect(() => {
    const zoneRef = ref(db, zoneCollectionPath);
    onValue(zoneRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const zonesData = Object.keys(data).map(key => ({ id: key, ...data[key] }));
        setZones(zonesData);
      }
    });
  }, [zoneCollectionPath, db]);

  const handleMapClick = (event) => {
    if (event.lat && event.lng) {
      setNewZone({ lat: event.lat, lng: event.lng });
    }
  };

  const handleSaveZone = async () => {
    if (newZone && bezeichnung) {
      const newZoneRef = push(ref(db, zoneCollectionPath));

     // const zoneRef = ref(db, `${zoneCollectionPath}/${bezeichnung}`);
      await update(newZoneRef, {
        bezeichnung,
        color: 2,
        latitude: newZone.lat,
        longitude: newZone.lng,       
        radius
      });
      setZones([...zones, { id: bezeichnung, bezeichnung, center: newZone, radius }]);
      setNewZone({ lat: -3.745, lng: -38.523 });  // Reset after save
      setbezeichnung('');
      setRadius(1000);
    }
  };

  const handleZoneClick = (zone) => {
    setbezeichnung(zone.bezeichnung);
    setRadius(zone.radius);
    setNewZone(zone.center);
  };

  const handleUpdateZone = async (zoneId) => {
    const zoneRef = ref(db, `${zoneCollectionPath}/${zoneId}`);
    await update(zoneRef, { bezeichnung, radius, center: newZone });
    setZones(zones.map(zone => zone.id === zoneId ? { ...zone, bezeichnung, radius, center: newZone } : zone));
    setNewZone({ lat: -3.745, lng: -38.523 });  // Reset after update
    setbezeichnung('');
    setRadius(1000);
  };

  const handleDeleteZone = async (zoneId) => {
    const zoneRef = ref(db, `${zoneCollectionPath}/${zoneId}`);
    await remove(zoneRef);
    setZones(zones.filter(zone => zone.id !== zoneId));
  };

  return (
    <div>
      <div style={{ height: '400px', width: '800px' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
          defaultCenter={newZone}
          defaultZoom={10}
          onClick={handleMapClick}
        >
           {zones.map(zone => (
            zone.center ? (
              <MapMarker
                key={zone.id}
                lat={zone.center.lat}
                lng={zone.center.lng}
                text={zone.name}
              />
            ) : null
          ))}
          {newZone && (
            <MapMarker
              lat={newZone.lat}
              lng={newZone.lng}
              text="New Zone"
            />
          )}
        </GoogleMapReact>
      </div>
      <div>
        <input type="text" value={bezeichnung} onChange={(e) => setbezeichnung(e.target.value)} placeholder="Zone Bezeichnung" />
        <input type="number" value={radius} onChange={(e) => setRadius(Number(e.target.value))} placeholder="Radius (meters)" />
        <button onClick={handleSaveZone}>Save Zone</button>
        {newZone && zones.map(zone => (
          <div key={zone.id}>
            <button onClick={() => handleUpdateZone(zone.id)}>Update Zone</button>
            <button onClick={() => handleDeleteZone(zone.id)}>Delete Zone</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GoogleMapComponent;
