import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Table, Alert, Container, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ref, getDatabase, update, onValue, remove, get } from "firebase/database";

const TankungenDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { FID, fzid } = location.state || {};
  const [editTankung, setEditTankung] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [currentTankungen, setCurrentTankungen] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  const formatDate = useCallback((timestamp) => {
    if (!timestamp) return "Ungültig";
    const date = new Date(timestamp);
    return `${date.toLocaleDateString("de-DE")} ${date.toLocaleTimeString("de-DE")}`;
  }, []);

  const fetchTankungen = useCallback(async () => {
    const db = getDatabase();
    const tankungRef = ref(db, `tankungen/${FID}/${fzid}`);
    try {
      const snap = await get(tankungRef);
      if (snap.exists()) {
        const data = snap.val();
        return Object.entries(data).map(([key, value]) => ({ id: key, ...value }));
      }
      return [];
    } catch (error) {
      setError("Fehler beim Laden der Tankungen.");
      return [];
    }
  }, [FID, fzid]);

  const fetchMitarbeiter = useCallback(async () => {
    const db = getDatabase();
    const usersRef = ref(db, `users/${FID}`);
    return new Promise((resolve, reject) => {
      onValue(
        usersRef,
        (snap) => {
          if (snap.exists()) {
            const data = snap.val();
            resolve(Object.entries(data).map(([key, value]) => ({ id: key, ...value })));
          } else {
            resolve([]);
          }
        },
        reject
      );
    });
  }, [FID]);

  useEffect(() => {
    if (FID) {
      fetchMitarbeiter().then(setAllUsers);
    }
  }, [FID, fetchMitarbeiter]);

  useEffect(() => {
    if (FID && fzid) {
      fetchTankungen().then(setCurrentTankungen);
    }
  }, [FID, fzid, fetchTankungen]);

  const handleEdit = (tankung) => {
    setEditTankung(tankung);
    setError(null);
    setSuccess(null);
  };

  const handleSave = async () => {
    const db = getDatabase();
    const tankungRef = ref(db, `tankungen/${FID}/${fzid}/${editTankung.id}`);
    try {
      await update(tankungRef, { liter: editTankung.liter });
      setSuccess("Tankung erfolgreich aktualisiert.");
      fetchTankungen().then(setCurrentTankungen);
      setEditTankung(null);
    } catch {
      setError("Fehler beim Speichern der Tankung.");
    }
  };

  const handleCancel = () => {
    setEditTankung(null);
    setError(null);
    setSuccess(null);
  };

  const handleDelete = async (id) => {
    const db = getDatabase();
    const tankungRef = ref(db, `tankungen/${FID}/${fzid}/${id}`);
    try {
      await remove(tankungRef);
      setSuccess("Tankung erfolgreich gelöscht.");
      fetchTankungen().then(setCurrentTankungen);
    } catch {
      setError("Fehler beim Löschen der Tankung.");
    }
  };

  const getUserNameById = (userId) => {
    const user = allUsers.find((user) => user.id === userId);
    return user ? user.name : "Unbekannt";
  };

  return (
    <Container fluid>
      <Button className="mt-3" variant="primary" onClick={() => navigate(-1)}>
        Zurück
      </Button>
      <h3 className="text-center my-4">Detaillierte Übersicht der Tankungen</h3>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>Datum</th>
            <th>Liter</th>
            <th>Preis</th>
            <th>KM-Stand</th>
            <th>Verbrauch</th>
            <th>Distanz</th>
            <th>Betriebsstoff</th>
            <th>Fahrer</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {currentTankungen.length > 0 ? (
            currentTankungen.map((tankung) => (
              <tr key={tankung.id}>
                <td>{formatDate(tankung.createdatetime)}</td>
                <td>
                  {editTankung?.id === tankung.id ? (
                    <Form.Control
                      type="number"
                      value={editTankung.liter}
                      onChange={(e) =>
                        setEditTankung({ ...editTankung, liter: parseFloat(e.target.value) })
                      }
                    />
                  ) : (
                    tankung.liter
                  )}
                </td>
                <td>{tankung.preis}</td>
                <td>{tankung.kmstand}</td>
                <td>{tankung.verbrauch.toFixed(2)}</td>
                <td>{tankung.distanz}</td>
                <td>{tankung.sorte}</td>
                <td>{getUserNameById(tankung.userid)}</td>
                <td>
                  {editTankung?.id === tankung.id ? (
                    <>
                      <Button variant="success" onClick={handleSave}>
                        Speichern
                      </Button>
                      <Button variant="secondary" onClick={handleCancel} className="ms-2">
                        Abbrechen
                      </Button>
                    </>
                  ) : (
                    <>
                      <OverlayTrigger overlay={<Tooltip>Bearbeiten</Tooltip>}>
                        <Button variant="primary" onClick={() => handleEdit(tankung)}>
                          Bearbeiten
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger overlay={<Tooltip>Löschen</Tooltip>}>
                        <Button variant="danger" onClick={() => handleDelete(tankung.id)} className="ms-2">
                          Löschen
                        </Button>
                      </OverlayTrigger>
                    </>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9" className="text-center">
                Keine Tankungen gefunden.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default TankungenDetail;
