import React, { useState,useEffect } from "react";
import { Card } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../context/authContext";
import UserStoppsCounter from "../pages/userstopps/UserStoppsCounter"
import PalettenKonto from "../pages/palettenkonto/PalettenKonto"


function Dashbord() {
  const navigate = useNavigate();
  const { currentUser,currentFirma,currentUserDetail,currentMitarbeiter} = useAuth();
  const [isLoading, setisLoading] = useState(true);
 
  

  useEffect(() => {    
    setisLoading(true); 
  
    if (!currentUser) 
      navigate("/login", { replace: true });
    if (
     
      currentFirma !== undefined && currentFirma !== null &&
      currentUserDetail !== undefined && currentUserDetail !== null &&
      currentMitarbeiter !== undefined && currentMitarbeiter !== null
      ){
       setisLoading(false);
    } 
       
  },[currentFirma, currentUserDetail, currentMitarbeiter, currentUser, navigate]);



  return (
    
    <>
      {!isLoading && currentUser?
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Dashbord</h2>
          
          <>
          <div className="App">
            <UserStoppsCounter />
            <PalettenKonto/>
          </div>
            
            
           
            
          </>
         
          
        </Card.Body>
      </Card>
    :<></>}
    </>
  );
 
}
export default Dashbord;