import React, { useEffect, useState, useMemo } from "react";
import { Card } from "react-bootstrap";
import { ref, getDatabase, onValue } from "firebase/database";
import { useAuth } from "../../../context/authContext";

const usePalettenKontoData = (UID, FID) => {
  const db = getDatabase();
  const [summary, setSummary] = useState({
    totalTagessaldoFP: 0,
    totalTagessaldoGP: 0,
    totalLeergutAbholungFP: 0,
    totalLeergutAbholungGP: 0,
    diffFP: 0,
    diffGP: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (FID && UID && db) {
      const userRef = ref(db, "palettenkonto/" + UID);
      setIsLoading(true);

      onValue(userRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          let totalTagessaldoFP = 0;
          let totalTagessaldoGP = 0;
          let totalLeergutAbholungFP = 0;
          let totalLeergutAbholungGP = 0;

          // Durchlaufe alle Tage und summiere die Werte
          Object.values(data).forEach((day) => {
            totalTagessaldoFP += day.tagessaldo.fp || 0;
            totalTagessaldoGP += day.tagessaldo.gp || 0;
            totalLeergutAbholungFP += day.leergutabholung.fp || 0;
            totalLeergutAbholungGP += day.leergutabholung.gp || 0;
          });

          // Berechne Differenzen
          const diffFP = totalTagessaldoFP - totalLeergutAbholungFP;
          const diffGP = totalTagessaldoGP - totalLeergutAbholungGP;

          setSummary({
            totalTagessaldoFP,
            totalTagessaldoGP,
            totalLeergutAbholungFP,
            totalLeergutAbholungGP,
            diffFP,
            diffGP,
          });
        } else {
          setError("Keine Daten gefunden.");
        }
        setIsLoading(false);
      }, (error) => {
        setError("Fehler beim Laden der Daten: " + error.message);
        setIsLoading(false);
      });
    }
  }, [UID, db, FID]);

  return { summary, isLoading, error };
};

const formatNumber = (num) => {
  return num.toLocaleString('de-DE');
};

const PalettenKonto = () => {
  const [UID, setUID] = useState("");
  const { currentUser } = useAuth();
  
  useEffect(() => {
    if (currentUser) {
      setUID(currentUser.uid);
    }
  }, [currentUser]);

  const FID = currentUser?.photoURL; // FID wird hier direkt zugewiesen
  const { summary, isLoading, error } = usePalettenKontoData(UID, FID);

  return (
    <div className="container">
      <div className="row justify-content-center mt-4">
        <div className="col-md-8 col-lg-6">
          <Card className="shadow-lg border-0 rounded-lg">
            <Card.Header className="bg-primary text-white py-3">
              <h4 className="mb-0 text-center">Palettenkonto Übersicht</h4>
            </Card.Header>
            <Card.Body className="bg-light">
              {isLoading ? (
                <div className="text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Lade Daten...</span>
                  </div>
                </div>
              ) : error ? (
                <p className="text-danger">{error}</p>
              ) : (
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <h5 className="text-primary">Summe Tagessaldo</h5>
                    <p className="mb-0">
                        FP: <span className="font-weight-bold">{formatNumber(summary.totalTagessaldoFP)}</span>
                    </p>
                    <p>GP: <span className="font-weight-bold">{formatNumber(summary.totalTagessaldoGP)}</span></p>
                  </div>
                  <div className="col-md-6 mb-3">
                    <h5 className="text-success">Summe Leergutabholung</h5>
                    <p className="mb-0">FP: <span className="font-weight-bold">{formatNumber(summary.totalLeergutAbholungFP)}</span></p>
                    <p>GP: <span className="font-weight-bold">{formatNumber(summary.totalLeergutAbholungGP)}</span></p>
                  </div>
                  <div className="col-12">
                    <h5 className="text-info">Differenzen</h5>
                    <p className="mb-0">FP: <span className="font-weight-bold">{formatNumber(summary.diffFP)}</span></p>
                    <p>GP: <span className="font-weight-bold">{formatNumber(summary.diffGP)}</span></p>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default PalettenKonto;