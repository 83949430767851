import React, { useState, useEffect } from "react";
import { set as set1 } from "date-fns";
import { ref, getDatabase, query } from "firebase/database";
import { useObjectVal } from 'react-firebase-hooks/database';
import { InputGroup, Card, Form, Col, Row, Button, Tabs, Tab, Spinner,Table } from 'react-bootstrap';
import { SliderPicker } from 'react-color';
import Select from 'react-select';
import '../mitarbeiterCard.css';
import useWindowDimensions from '../../../useMediaQuery';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from 'date-fns/locale/de';
import {useAuth}from "../../../../context/authContext";


registerLocale("de", de);



function MForm({ showNewUser, childToParent, setShowNewUser, formdata, IsnewUser }) {
  const db = getDatabase();
  const {currentUser} =  useAuth();
  
  const FID = currentUser.photoURL;
  const GetDate = (hour, minutes) => {
    return set1(new Date(), {
      seconds: 0,
      hours: hour,
      milliseconds: 0,
      minutes: minutes
    });
  };
  const [passwort, setPasswort] = useState('');
  const [validated, setValidated] = useState(true);

  
  const [userid, setuserid] = useState('');
  const [data, setFormData] = useState({
    color: '#697689',
    aktiveDate: new Date().getTime(),
    createDate: new Date().getTime(),
    fid: FID,
    isAktiv: true,
    isLohnabrechnungen: true,
    isSpesen: true,
    isDelete: false,
    name: "",
    email: "",
    telefon: "",
    strasse: '',
    plz: "",
    ort: "",
    rollen: [{ value: 40, label: 'Fahrer' }]


  });
  //const [arbeitzeiten, setarbeitzeiten] = useState({
  //  arbeitsbeginn: GetDate(6, 0),
  //  arbeitsende: GetDate(14, 30)
  //});
  const [mhd, setmhd] = useState({
    fahrerkarteauslesen: 0,
    firmeneintritt: 0,
    geburtstag: 0,
    adr: 0,
    fahrerkarte: 0,
    bkrfqg: 0,
    fuehrerschein: 0
  });

  const [AZeiten, setAzeiten] = useState(
    [
      {wochentag:"Montag" , wochentagID: 0, beginn: GetDate(6, 0),ende:GetDate(14, 30) },
      {wochentag:"Dienstag" ,wochentagID: 1, beginn: GetDate(6, 0),ende:GetDate(14, 30) },
      {wochentag:"Mittwoch" ,wochentagID: 2, beginn: GetDate(6, 0),ende:GetDate(14, 30) },
      {wochentag:"Donnertag" ,wochentagID: 3, beginn: GetDate(6, 0),ende:GetDate(14, 30) },
      {wochentag:"Freitag" ,wochentagID: 4, beginn: GetDate(6, 0),ende:GetDate(14, 30) }
  ]
  );




  const UArbeitzeitenRef = query(ref(db, `arbeitzeiten/` + FID + '/' + formdata.key + '/' + 'arbeitzeiten'));
  const [Arbeitzeitenvalues, loadingArbeitzeit] = useObjectVal(UArbeitzeitenRef);

  const UMHDRef = query(ref(db, `mhd/` + FID + '/' + formdata.key));
  const [MHDvalues, loadingMHD] = useObjectVal(UMHDRef);

  


  useEffect(() => {
    if (!IsnewUser) {
     
      if (Arbeitzeitenvalues) {
        console.log(Arbeitzeitenvalues)
        console.log(AZeiten)
        Arbeitzeitenvalues.map((az,index) => (
          AZeiten[index].beginn = GetDate(new Date(az.arbeitsbeginn).getHours(), new Date(az.arbeitsbeginn).getMinutes()),
          AZeiten[index].ende = GetDate(new Date(az.arbeitsende).getHours(), new Date(az.arbeitsende).getMinutes())
        ))
        setAzeiten(AZeiten);
      //  setarbeitzeiten({
      //    ...arbeitzeiten,
      //    arbeitsbeginn: GetDate(new Date(Arbeitzeitenvalues.arbeitsbeginn).getHours(), new Date(Arbeitzeitenvalues.arbeitsbeginn).getMinutes()),
      //    arbeitsende: GetDate(new Date(Arbeitzeitenvalues.arbeitsende).getHours(), new Date(Arbeitzeitenvalues.arbeitsende).getMinutes())
      //  })
      
       // setSelectedInterval([
       //   GetDate(new Date(Arbeitzeitenvalues.arbeitsbeginn).getHours(), new Date(Arbeitzeitenvalues.arbeitsbeginn).getMinutes()),
       //   GetDate(new Date(Arbeitzeitenvalues.arbeitsende).getHours(), new Date(Arbeitzeitenvalues.arbeitsende).getMinutes())
       // ])
      }
    }


  }, [IsnewUser,loadingArbeitzeit]);

  function errorHandler(error) {
    console.info({ error });
  }

  function onChangeCallback(change) {


  //  setarbeitzeiten(
  //    {
  //      ...arbeitzeiten,
  //      arbeitsbeginn: change[0],
  //      arbeitsende: change[1],
  //    }
  //  )
  //  setSelectedInterval(change);

  }


  useEffect(() => {

    if (!loadingMHD && !IsnewUser) {
      if(MHDvalues ===null){

      }else{
        setmhd(MHDvalues);
      };
      
    }


  }, [IsnewUser, MHDvalues, loadingMHD]);


  useEffect(() => {

    if (!IsnewUser) {
      setuserid(formdata.key)
      setFormData(formdata.user)
    }

  }, [IsnewUser, formdata]);

  useEffect(() => {



  }, [data]);


  const options = [
    { value: 10, label: 'Admin' },
    { value: 20, label: 'Fuhrparkleiter' },
    { value: 30, label: 'Fahrer+' },
    { value: 40, label: 'Fahrer' },
  ];


  const { height, width } = useWindowDimensions();





  const styles = {
    myCoolButton: {
      paddingRight: "1vw",

    }
  }



 

  if (!loadingMHD && !loadingArbeitzeit && AZeiten) {
    return (
      <>

        <Form noValidate validated={validated} onSubmit={(e) => childToParent(e, data, AZeiten, mhd, userid, passwort)} className="mt-3">
          <Card className={width < 768 ? 'bg-light align-items-stretch myCardsx' : 'align-items-stretch myCard'}>
            <Card.Header className='text-center'>Allgemeine angaben über den Neuen Mitarbeiter</Card.Header>

            <Card.Body className="d-flex flex-column">

              <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="home" title="Allgemeine Angaben">
                  <Row className="mb-3 ">
                    <Form.Group as={Col} md="6" style={styles.myCoolButton} controlId="validationCustom01">
                      <Form.Label>Vor & Nachname</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        autoComplete="name"
                        placeholder="Vor & Nachname"
                        value={data.name || ''}
                        onChange={(event) =>
                          setFormData({ ...data, name: event.target.value })
                        }
                      />
                      <Form.Control.Feedback>Sieht gut aus!</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} className="col-md-6" controlId="validationCustomUsername">
                      <Form.Label>Email Adresse</Form.Label>
                      <InputGroup hasValidation>
                        <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                        <Form.Control
                          type="email"
                          placeholder="Email Adresse"
                          autoComplete="email"
                          aria-describedby="inputGroupPrepend"
                          required
                          value={data.email || ''}
                          onChange={(event) =>
                            setFormData({ ...data, email: event.target.value })
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Bitte Email Adresse eingeben.
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Sieht gut aus!</Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>



                  </Row>

                  <Row className="mb-3 ">
                    {IsnewUser &&
                      <Form.Group as={Col} md="6" style={styles.myCoolButton} controlId="validationCustomPasswort">
                        <Form.Label>Passwort</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            type="password"
                            placeholder="Passwort"
                            autoComplete="new-password"
                            aria-describedby="inputGroupPrepend"
                            required minLength={6}
                            value={passwort}
                            onChange={(event) =>
                              setPasswort(event.target.value)
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Bitte ein Passwort von min 6 Zeichen eingeben.
                          </Form.Control.Feedback>
                          <Form.Control.Feedback>Sieht gut aus!</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    }

                    <Form.Group as={Col} className={IsnewUser ? "md=6" : "md=12"} controlId="validationCustom06">
                      <Form.Label>Handynummer</Form.Label>
                      <Form.Control
                        type="text"
                        autoComplete="tel"
                        placeholder="Handynummer"
                        required
                        value={data.telefon || ''}
                        onChange={(event) =>
                          setFormData({ ...data, telefon: event.target.value })
                        }
                      />
                      <Form.Control.Feedback type="invalid"> Bitte Handynummer eingeben! </Form.Control.Feedback>
                      <Form.Control.Feedback>Sieht gut aus!</Form.Control.Feedback>
                    </Form.Group>
                  </Row>

                  <Row className="mb-3 ">
                    <Form.Group as={Col} md="4" style={styles.myCoolButton} controlId="validationCustom04">
                      <Form.Label>Straße</Form.Label>
                      <Form.Control
                        type="Multiline"
                        autoComplete="street-address"
                        placeholder="Straße"
                        required
                        value={data.strasse || ''}
                        onChange={(event) =>
                          setFormData({ ...data, strasse: event.target.value })
                        }
                      />
                      <Form.Control.Feedback type="invalid">Bitte eine Straße eingeben.</Form.Control.Feedback>
                      <Form.Control.Feedback>Sieht gut aus!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" style={styles.myCoolButton} controlId="validationCustom03">
                      <Form.Label>Wohnort</Form.Label>
                      <Form.Control
                        type="text"
                        autoComplete="address-line2"
                        placeholder="Wohnort"
                        required
                        value={data.ort || ''}
                        onChange={(event) =>
                          setFormData({ ...data, ort: event.target.value })
                        }
                      />
                      <Form.Control.Feedback type="invalid"> Bitte einen Ort eingebn. </Form.Control.Feedback>
                      <Form.Control.Feedback>Sieht gut aus!</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="validationCustom05">
                      <Form.Label>PLZ</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="PLZ"
                        autoComplete="postal-code"
                        required
                        minLength={5}
                        maxLength={5}
                        pattern="[0-9]{5}"
                        value={data.plz || ''}
                        onChange={(event) =>
                          setFormData({ ...data, plz: event.target.value })
                        }
                      />

                      <Form.Control.Feedback type="invalid">Bitte eine Postleitzahl eingeben.</Form.Control.Feedback>
                      <Form.Control.Feedback>Sieht gut aus!</Form.Control.Feedback>
                    </Form.Group>
                  </Row>

                </Tab>
                <Tab eventKey="profile" title="spezifische Daten">
                  <Row className="mb-3 ">
                    <Form.Group as={Col} md="6" style={styles.myCoolButton} controlId="validationf">
                      <InputGroup.Text className="mb-2 " style={{ backgroundColor: data.color }}>Mitarbeiter Farbe: {data.color}</InputGroup.Text>
                      <SliderPicker

                        color={data.color || ''}
                        onChangeComplete={e => {
                          setFormData({ ...data, color: e.hex })
                        }
                        }
                      />
                    </Form.Group>



                    <Form.Group as={Col} md="6" controlId="validationr">
                      <InputGroup.Text className="mb-2 " id="basic-addon">Rollen des Mitarbeiters</InputGroup.Text>
                      <Select
                        className="mb-2"
                        isMulti
                        options={options}
                        value={data.rollen || ''}
                        onChange={(e) => {
                          setFormData(
                            { ...data, rollen: e }
                          )
                        }
                        }
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3 ">
                    <Form.Group as={Col} md="3" controlId="validationis MitarbeiterAktiv">
                      <Form.Check
                        label="Ist der Mitarbeiter Aktiv"
                        checked={data.isAktiv && !data.isDelete}
                        disabled={data.isDelete}
                        onChange={(e) => {
                          console.log(e.target.checked);
                          setFormData(
                            { ...data, isAktiv: e.target.checked }
                          )
                        }
                        }
                      />

                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationisgelöschtAktiv">
                      <Form.Check
                        label="Mitarbeiter gelöscht?"
                        checked={data.isDelete || ''}
                        onChange={(e) => {                         
                          setFormData(
                            { ...data, isDelete: e.target.checked }
                          )
                        }
                        }
                      />

                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationisLohnabrechnungen">


                   
                   {/* <label>
                      <span >Bekommt Lohnabrechnungen </span>
                      
                      <Switch
                        checked={data.isLohnabrechnungen || ''}
                        onChange={(e) => {
                          setFormData(
                            { ...data, isLohnabrechnungen: e.target.checked }
                          )
                        }
                        }
                        height={16}
                        width={32}                        
                        offColor='#f00'
                        className="react-switch align-middle"
                        id="lohnabrechnungen-switch"
                      />
                    </label>*/}
                 

                      <Form.Check
                        label="Bekommt Lohnabrechnungen"
                        checked={data.isLohnabrechnungen || ''}
                        onChange={(e) => {
                          setFormData(
                            { ...data, isLohnabrechnungen: e.target.checked }
                          )
                        }
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationisSpesen">
                      <Form.Check
                        label="Bekommt Spesen"
                        checked={data.isSpesen || ''}
                        onChange={(e) => {
                          setFormData(
                            { ...data, isSpesen: e.target.checked }
                          )
                        }
                        }
                      />
                    </Form.Group>
                  </Row>

                

                  <Row className="mb-3 ">
                    <div className="mb-3">
                      <b> Meine Arbeitzeiten</b>
                     
                    </div>
                    <div className="mb-3">
                    <Table striped bordered hover >
                    <thead>
                      <tr>                       
                        <th>Wochentag</th>
                        <th>Beginn-Zeit</th>
                        <th>Ende-Zeit</th>
                      </tr>
                    </thead>
                    <tbody>


                     {
                      Array.isArray(AZeiten)
                     ? AZeiten.map((az,index) => (
                      
                      <tr key={index}>
                          
                          <td>{az.wochentag}</td>
                          <td> 
                          <DatePicker 
                           
                            selected={az.beginn}
                            onChange={(date) => {
                              az.beginn = date
                              setAzeiten([...AZeiten])
                            }
                            }                        
                            showTimeSelect
                            showTimeSelectOnly
                            locale="de"
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="Beginn"
                            dateFormat="HH:mm"
                          />
                        </td>
                        <td> 
                          <DatePicker 
                           
                            selected={az.ende}
                            onChange={(date) => {
                              az.ende = date
                              setAzeiten([...AZeiten])
                            }   
                            }                             
                            showTimeSelect
                            showTimeSelectOnly
                            locale="de"
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="Ende"
                            dateFormat="HH:mm"
                          />
                        </td>
                      </tr>
                        ))
                        :console.log(AZeiten)
                    }
                       
                     
                     
                    </tbody>
                  </Table>
                  </div>
                   

                 
                  </Row>
                </Tab>
                <Tab eventKey="date" title="Verfalldaten">
                  <div className="row mb-3">
                    <div className="col-md-6" >
                      <InputGroup.Text className="h-100">Ablaufdatum des Führerscheins</InputGroup.Text>
                    </div>
                    <div className="col-md-6" >
                      <DatePicker
                        className="w-100"
                        placeholderText="Ablaufdatum des Führerscheins"
                        locale="de"
                        selected={mhd.fuehrerschein || 0}
                        onSelect={(e) => {

                        }}
                        onChange={(e) => {
                          setmhd({
                            ...mhd,
                            fuehrerschein: e.getTime()
                          });
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        scrollableYearDropdown
                        dateFormat="dd.MM.yyyy"
                        dropdownMode="select"
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6" >
                      <InputGroup.Text className="h-100">Ablaufdatum der BKrFQG(95)</InputGroup.Text>
                    </div>
                    <div className="col-md-6" >
                      <DatePicker
                        className="w-100"
                        locale="de"
                        selected={mhd.bkrfqg || ''}
                        placeholderText="Ablaufdatum der BKrFQG(95)"
                        onChange={(e) => {
                          setmhd({
                            ...mhd,
                            bkrfqg: e.getTime()
                          });
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        scrollableYearDropdown
                        dateFormat="dd.MM.yyyy"
                        dropdownMode="select"
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6" >
                      <InputGroup.Text className="h-100">Ablaufdatum der Fahrerkarte</InputGroup.Text>
                    </div>
                    <div className="col-md-6" >
                      <DatePicker
                        className="w-100"
                        locale="de"
                        selected={mhd.fahrerkarte || ''}
                        placeholderText="Ablaufdatum der Fahrerkarte"
                        onChange={(e) => {
                          setmhd({
                            ...mhd,
                            fahrerkarte: e.getTime()
                          });
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        scrollableYearDropdown
                        dateFormat="dd.MM.yyyy"
                        dropdownMode="select"
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6" >
                      <InputGroup.Text className="h-100">Ablaufdatum von ADR</InputGroup.Text>
                    </div>
                    <div className="col-md-6" >
                      <DatePicker
                        className="w-100"
                        locale="de"
                        selected={mhd.adr || ''}
                        placeholderText="Ablaufdatum von ADR"
                        onChange={(e) => {
                          setmhd({
                            ...mhd,
                            adr: e.getTime()
                          });
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        scrollableYearDropdown
                        dateFormat="dd.MM.yyyy"
                        dropdownMode="select"
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6" >
                      <InputGroup.Text className="h-100">Geburtstag</InputGroup.Text>
                    </div>
                    <div className="col-md-6" >
                      <DatePicker
                        className="w-100"
                        locale="de"
                        selected={mhd.geburtstag || ''}
                        placeholderText="Geburtstag"
                        onChange={(e) => {
                          setmhd({
                            ...mhd,
                            geburtstag: e.getTime()
                          });
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        scrollableYearDropdown
                        dateFormat="dd.MM.yyyy"
                        dropdownMode="select"
                      />
                    </div>
                  </div>


                  <div className="row mb-3">
                    <div className="col-md-6" >
                      <InputGroup.Text className="h-100">Firmeneintritt</InputGroup.Text>
                    </div>
                    <div className="col-md-6" >
                      <DatePicker
                        className="w-100"
                        locale="de"
                        selected={mhd.firmeneintritt || ''}
                        placeholderText="Firmeneintritt"
                        onChange={(e) => {
                          setmhd({
                            ...mhd,
                            firmeneintritt: e.getTime()
                          });
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        scrollableYearDropdown
                        dateFormat="dd.MM.yyyy"
                        dropdownMode="select"
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-6" >
                      <InputGroup.Text className="h-100">Fahrerkarteauslesen</InputGroup.Text>
                    </div>
                    <div className="col-md-6" >
                      <DatePicker
                        className="w-100"
                        locale="de"
                        selected={mhd.fahrerkarteauslesen || ''}
                        placeholderText="Fahrerkarteauslesen"
                        onChange={(e) => {
                          setmhd({
                            ...mhd,
                            fahrerkarteauslesen: e.getTime()
                          });
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        scrollableYearDropdown
                        dateFormat="dd.MM.yyyy"
                        dropdownMode="select"
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>

            </Card.Body>
            <Card.Footer>
              <div className="row">
                <div className="col d-grid gap-2 mt-auto">
                  <Button variant="outline-danger "
                    onClick={() => {
                      setShowNewUser(false);
                    }}
                  >
                    Abbrechen
                  </Button>
                </div>
                <div className="col d-grid gap-2 mt-auto">
                  <Button className="float-right" variant="outline-success" type="submit">{IsnewUser ? "Speichern" : "Update"}</Button>
                </div>
              </div>


            </Card.Footer>

          </Card>
        </Form>



      </>





    );
  } else {
    return (
      <>
        <div className="home">
          <div className="text-center">
            <Button variant="primary" size="lg" disabled>
              <Spinner
                as="span"
                animation="grow"
                size="lg"
                role="status"
                aria-hidden="true"
              />
              Loading...
            </Button>

          </div>
        </div>
      </>
    )
  }
}
export default MForm;
