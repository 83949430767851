import React, { useEffect, useState } from "react";
import { Table, Card } from "react-bootstrap";
import { ref, getDatabase, onValue } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/authContext";

const usePalettenKontoData = () => {
  const db = getDatabase();
  const [usersData, setUsersData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [FID, setFID] = useState("");
  const { currentUser, isAdmin } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      setFID(currentUser.photoURL); // FID ist die Firmen-ID des aktuellen Benutzers
    }
  }, [currentUser]);

  useEffect(() => {
    if (!isAdmin) {
      navigate(-1);
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    if (db && FID) {
      const userRef = ref(db, "palettenkonto");
      setIsLoading(true);

      onValue(userRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const users = Object.keys(data).map((userId) => {
            const user = data[userId];
            let totalTagessaldoFP = 0;
            let totalTagessaldoGP = 0;
            let totalLeergutAbholungFP = 0;
            let totalLeergutAbholungGP = 0;

            // Durchlaufe alle Tage und summiere die Werte
            Object.values(user).forEach((day) => {
              totalTagessaldoFP += day.tagessaldo.fp || 0;
              totalTagessaldoGP += day.tagessaldo.gp || 0;
              totalLeergutAbholungFP += day.leergutabholung.fp || 0;
              totalLeergutAbholungGP += day.leergutabholung.gp || 0;
            });

            const diffFP = totalTagessaldoFP - totalLeergutAbholungFP;
            const diffGP = totalTagessaldoGP - totalLeergutAbholungGP;

            return {
              userId,
              totalTagessaldoFP,
              totalTagessaldoGP,
              totalLeergutAbholungFP,
              totalLeergutAbholungGP,
              diffFP,
              diffGP,
            };
          });

          // Nun die Benutzerdaten für Namen laden
          const usersRef = ref(db, "users");
          onValue(usersRef, (userSnapshot) => {
            if (userSnapshot.exists()) {
              const usersNameData = userSnapshot.val();
              const updatedUsersData = users.map((user) => {
                const userName = usersNameData[FID][user.userId]?.name || "Unbekannt";
                return {
                  ...user,
                  userName,
                };
              });

              setUsersData(updatedUsersData);
            }
          });
        } else {
          setError("Keine Daten gefunden.");
        }
        setIsLoading(false);
      }, (error) => {
        setError("Fehler beim Laden der Daten: " + error.message);
        setIsLoading(false);
      });
    }
  }, [db, FID]);

  // Berechnung der Gesamtwerte für Differenz FP und GP
  const totalDiffFP = usersData.reduce((acc, user) => acc + user.diffFP, 0);
  const totalDiffGP = usersData.reduce((acc, user) => acc + user.diffGP, 0);

  return { usersData, isLoading, error, totalDiffFP, totalDiffGP };
};

const formatNumber = (num) => {
  return num.toLocaleString("de-DE");
};

const PalettenKonto = () => {
  const { usersData, isLoading, error, totalDiffFP, totalDiffGP } = usePalettenKontoData();

  // Funktion zum Formatieren der Zahl (z.B. Tausendertrennzeichen)
  const formatNumber = (num) => {
    return num.toLocaleString("de-DE");
  };

  // Funktion zur Bestimmung der Hintergrundfarbe für die Differenzwerte
  const getColorForDiff = (value) => {
    return value <= 0 ? "bg-success" : "bg-danger"; // Grün für <= 0, Rot für > 0
  };

  return (
    <div className="container">
      <div className="row justify-content-center mt-4">
        <div className="col-md-8 col-lg-10">
          <Card className="shadow-lg border-0 rounded-lg">
            <Card.Header className="bg-primary text-white py-3">
              <h4 className="mb-0 text-center">Palettenkonto Übersicht</h4>
            </Card.Header>
            <Card.Body className="bg-light">
              {isLoading ? (
                <div className="text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Lade Daten...</span>
                  </div>
                </div>
              ) : error ? (
                <p className="text-danger">{error}</p>
              ) : (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Summe Tagessaldo FP</th>
                      <th>Summe Tagessaldo GP</th>
                      <th>Summe Leergutabholung FP</th>
                      <th>Summe Leergutabholung GP</th>
                      <th>Differenz FP</th>
                      <th>Differenz GP</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersData.map((user) => (
                      <tr key={user.userId}>
                        <td>{user.userName}</td>
                        <td>{formatNumber(user.totalTagessaldoFP)}</td>
                        <td>{formatNumber(user.totalTagessaldoGP)}</td>
                        <td>{formatNumber(user.totalLeergutAbholungFP)}</td>
                        <td>{formatNumber(user.totalLeergutAbholungGP)}</td>
                        <td className={getColorForDiff(user.diffFP)}>{formatNumber(user.diffFP)}</td>
                        <td className={getColorForDiff(user.diffGP)}>{formatNumber(user.diffGP)}</td>
                      </tr>
                    ))}
                    {/* Gesamtzeile */}
                    <tr>
                      <td><strong>Gesamt</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className={getColorForDiff(totalDiffFP)}><strong>{formatNumber(totalDiffFP)}</strong></td>
                      <td className={getColorForDiff(totalDiffGP)}><strong>{formatNumber(totalDiffGP)}</strong></td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default PalettenKonto;
