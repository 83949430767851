import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Navbar,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import { useAuth } from "../../../context/authContext";
import { ref, getDatabase, onValue } from "firebase/database";
import { Link } from "react-router-dom";

export const Tankungen = () => {
  const { currentUser, isAdmin } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const db = getDatabase();

  const [loadingFahrzeug, setLoadingFahrzeug] = useState(true);
  const [loadingTankungen, setLoadingTankungen] = useState(true);
  const [loadingCombinedData, setLoadingCombinedData] = useState(true);
  const [sortFahrzeug, setSortFahrzeug] = useState([]);
  const [sortTankungen, setSortTankungen] = useState([]);
  const [FID, setFID] = useState("");

  const formatDate = useCallback((datetime) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Intl.DateTimeFormat("de-DE", options).format(datetime);
  }, []);

  const formatNumber = useCallback((number) => {
    return new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  }, []);

  const routeChange = () => {
    navigate("/login");
  };

  useEffect(() => {
    if (currentUser) {
      setFID(currentUser.photoURL);
    }
  }, [currentUser]);

  useEffect(() => {
    if (!isAdmin) {
      navigate("/Dashbord", { replace: true });
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    if (FID) {
      const fahrzeugRef = ref(db, `fahrzeuge/${FID}`);
      setLoadingFahrzeug(true);
      onValue(fahrzeugRef, (snap) => {
        if (snap.exists()) {
          const data = snap.val();
          const unsorted = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          const activeFahrzeuge = unsorted.filter((fz) => fz.isAktiv);
          setSortFahrzeug(activeFahrzeuge);
        } else {
          setSortFahrzeug([]);
        }
        setLoadingFahrzeug(false);
      });
    }
  }, [FID, db]);

  useEffect(() => {
    if (sortFahrzeug.length > 0) {
      const tankungRef = ref(db, `tankungen/${FID}`);
      setLoadingTankungen(true);
      onValue(tankungRef, (snap) => {
        if (snap.exists()) {
          const data = snap.val();
          const unsorted = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          const filteredTankungen = unsorted.filter((tankung) =>
            sortFahrzeug.some((fz) => fz.id === tankung.id)
          );
          setSortTankungen(filteredTankungen);
        } else {
          setSortTankungen([]);
        }
        setLoadingTankungen(false);
      });
    }
  }, [FID, sortFahrzeug, db]);

  useEffect(() => {
    if (!loadingFahrzeug && !loadingTankungen) {
      setLoadingCombinedData(false);
    }
  }, [loadingFahrzeug, loadingTankungen]);

  if (loadingFahrzeug || loadingTankungen || loadingCombinedData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {currentUser ? (
        <Container fluid>
          <div className="text-center mb-4 mt-3">
            <h4>Tankungen</h4>
          </div>
          <Row xs={1} md={2} lg={3} className="g-4">
            {sortFahrzeug.map((FZ) => {
              const vehicleTankungen = sortTankungen.filter(
                (tankung) => tankung.id === FZ.id
              );
  
              let numberOfTankungen = 0;
              let totalLitersDiesel = 0;
              let totalLitersAdBlue = 0;
              let zurueckgelegteKm = 0;
              let verbrauchPro100KmDiesel = 0;
              let verbrauchPro100KmAdBlue = 0;
  
              if (Array.isArray(vehicleTankungen) && vehicleTankungen.length > 0) {
                const tankungsList = Object.values(vehicleTankungen[0] || {})
                  .filter((tankung) => typeof tankung === "object" && tankung.distanz > 0); // Tankungen mit distanz = 0 ignorieren
  
                // Diesel-Tankungen filtern
                const dieselTankungen = tankungsList.filter(
                  (tankung) => tankung.sorte === "Diesel"
                );
                const adBlueTankungen = tankungsList.filter(
                  (tankung) => tankung.sorte === "AdBlue"
                );
  
                numberOfTankungen = tankungsList.length;
  
                // Diesel-Berechnungen
                totalLitersDiesel = dieselTankungen.reduce(
                  (sum, tankung) => sum + (tankung.liter || 0),
                  0
                );
                zurueckgelegteKm = dieselTankungen.reduce(
                  (sum, tankung) => sum + (tankung.distanz || 0),
                  0
                );
                verbrauchPro100KmDiesel =
                  zurueckgelegteKm > 0 ? (totalLitersDiesel / zurueckgelegteKm) * 100 : 0;
  
                // AdBlue-Berechnungen
                totalLitersAdBlue = adBlueTankungen.reduce(
                  (sum, tankung) => sum + (tankung.liter || 0),
                  0
                );
                verbrauchPro100KmAdBlue =
                  zurueckgelegteKm > 0 ? (totalLitersAdBlue / zurueckgelegteKm) * 100 : 0;
              }
  
              return (
                <Col key={FZ.kennzeichen}>
                  <Card className="h-100">
                    <Card.Header className="text-center">
                      {FZ.kennzeichen}
                    </Card.Header>
                    <Card.Body>
                      <Card.Title>KM-Stand: {FZ.kmStand}</Card.Title>
                      <Card.Text>
                        {numberOfTankungen > 0 ? (
                          <>
                            <p>Anzahl der Tankungen: {numberOfTankungen}</p>
                            <p>Diesel Gesamtmenge: {formatNumber(totalLitersDiesel)} Liter</p>
                            <p>AdBlue Gesamtmenge: {formatNumber(totalLitersAdBlue)} Liter</p>
                            <p>Gesamtdistanz: {formatNumber(zurueckgelegteKm)} KM</p>
                            <p>
                              Diesel Verbrauch pro 100 KM:{" "}
                              {formatNumber(verbrauchPro100KmDiesel)} Liter
                            </p>
                            <p>
                              AdBlue Verbrauch pro 100 KM:{" "}
                              {formatNumber(verbrauchPro100KmAdBlue)} Liter
                            </p>
                          </>
                        ) : (
                          <p>Keine Tankungen verfügbar</p>
                        )}
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="text-center">
                      <Link
                        to={`/tankungen/detail`}
                        state={{ FID, fzid: FZ.id }}
                        className="btn btn-primary"
                      >
                        Details anzeigen
                      </Link>
                    </Card.Footer>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      ) : (
        <div className="text-center">
          <h3>404 Seite nicht gefunden</h3>
          <Button onClick={routeChange}>Zurück zum Login</Button>
        </div>
      )}
    </>
  );
  
  
};
